
.treeWrapper {
    height: 77vh;
}

.node__root > circle{
    fill: black;
    stroke: black;
}

.node__branch > circle,
.node__leaf > circle {
    fill: #16ab39;
    stroke-width: 0
}

.ui.horizontal.list:not(.celled) > .item:first-child {
    margin-left: 1em !important;
    padding-left: 1em !important;
}