.order-green-list-item {
    color: #21ba45;
}

.order-red-list-item {
    color: #db2828;
}

.ui.visible.popup {
    height: min-content;
}